<template>
  <div class="elite-tabs-wrapper-content">
    <repair-ref-standardized
      v-if="viewHeader"
      :show-buttons="showButtons"
      :canCancel="showButtons && canCancelRepair(repair)"
      :canEdit="showButtons && canEditRepair(repair)"
      :repair="repair"
      @cancel="showRepairCancelModel = true"
      @edit="editRepair"
      @vehicleChanged="$emit('onViewRepair')"
    />

    <slot name="header">
      <div class="global-header align-items-baseline justify-content-between">
        <h3>
          {{ repair.code }}
        </h3>
        <div class="global-header-text">
          <div class="buttons">
            <slot name="buttons">
              <!-- <button v-if="canCancel" @click="$emit('cancel')" class="delete">
                <span> {{ $t("COMMON.CANCEL") }} </span>
                <i class="far fa-trash-alt"></i>
              </button> -->
              <button
                v-if="showButtons && canEditRepair(repair)"
                @click="editRepair"
                class="edit"
              >
                <span> {{ $t("REPAIRS.UPDATE") }} </span>
                <i class="far fa-edit"></i>
              </button>
              <!-- <button v-if="canView" @click="view" class="edit">
                <span> {{ $t("ESTIMATES.SHOW_ESTIMATE") }} </span>
                <i class="fa-light fa-eye"></i>
              </button> -->
            </slot>
          </div>
        </div>
      </div>
    </slot>

    <div class="global-body">
      <dl class="width_1-2">
        <dt>{{ $t("COMMON.CODE") }}</dt>
        <dd>
          <object-link :object="repair" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="repair.work_order">
        <dt>{{ $t("COMMON.WORK_ORDER") }}</dt>
        <dd>
          {{ repair.work_order }}
        </dd>
      </dl>

      <dl class="width_1-2 status">
        <dt>{{ $t("COMMON.STATUS") }}</dt>
        <dd>
          <repair-status-badge :status="repair.status" :repair="repair" />
        </dd>
      </dl>

      <dl class="width_1-2">
        <dt>{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8" v-html="repair.excerpt"></dd>
      </dl>

      <dl class="width_1-2 status" v-if="repair.times_status">
        <dt>{{ $t("REPAIRS.TIMES_STATUS") }}</dt>
        <dd>
          <repair-status-badge :status="repair.times_status" :repair="repair">
            {{
              $tc(`REPAIRS.REPAIR_TYPES_STATUS_${repair.times_status}`)
                ? $t(`REPAIRS.REPAIR_TYPES_STATUS_${repair.times_status}`)
                : "-"
            }}
          </repair-status-badge>
        </dd>
      </dl>

      <dl class="width_1-2 status" v-if="repair.components_status">
        <dt>{{ $t("REPAIRS.COMPONENTS_STATUS") }}</dt>
        <dd>
          <repair-status-badge
            :status="repair.components_status"
            :repair="repair"
          >
            {{
              $tc(`REPAIRS.REPAIR_TYPES_STATUS_${repair.components_status}`)
                ? $t(`REPAIRS.REPAIR_TYPES_STATUS_${repair.components_status}`)
                : "-"
            }}
          </repair-status-badge>
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          $idExist(repair.customer?.id) &&
          !!repair.customer?.customer_type
        "
      >
        <dt>{{ $t("COMMON.CUSTOMER") }}</dt>
        <dd>
          <users :users="[repair.customer]" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          $idExist(repair.approver?.id) &&
          !!repair.approver?.email
        "
      >
        <dt>{{ $t("COMMON.APPROVER") }}</dt>
        <dd>
          <object-link :object="repair.approver" />
        </dd>
      </dl>

      <!-- <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
          !!repair.vehicle
        "
      >
        <dt>{{ $t("COMMON.VEHICLE") }}</dt>
        <dd>
          <object-link :object="repair.vehicle" />
        </dd>
      </dl>

      <dl class="width_1-2">
        <dt>{{ $t("VEHICLES.ODOMETER") }}</dt>
        <dd>
          {{ repair.odometer ?? repair.vehicle?.odometer }}
          {{ repair.vehicle?.odometer_unit }}
        </dd>
      </dl> -->

      <dl class="width_1-2">
        <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd>
          <locations :locations="repair.allowedLocations" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.created_at">
        <dt>{{ $t("REPAIRS.CREATED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.created_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) && !!repair.creator
        "
      >
        <dt>{{ $t("REPAIRS.CREATOR") }}</dt>
        <dd>
          <object-link :object="repair.creator" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.validated_at">
        <dt>{{ $t("REPAIRS.VALIDATED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.validated_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.validatedBy
        "
      >
        <dt>{{ $t("REPAIRS.VALIDATED_BY") }}</dt>
        <dd>
          <object-link :object="repair.validatedBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.assigned_at">
        <dt>{{ $t("REPAIRS.ASSIGNED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.assigned_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.assignedToUsers?.length
        "
      >
        <dt>{{ $t("REPAIRS.ASSIGNED_TO") }}</dt>
        <dd>
          <span v-for="(user, idx) in repair.assignedToUsers" :key="idx">
            <object-link :object="user" />
            {{ repair.assignedToUsers.length - 1 > idx ? "," : " " }}<br />
          </span>
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ROLES) &&
          !!repair.assignedToRoles?.length
        "
      >
        <dt>{{ $t("REPAIRS.ASSIGNED_TO_GROUP") }}</dt>
        <dd>
          <span v-for="(role, idx) in repair.assignedToRoles" :key="idx">
            <object-link :object="role" />
            {{ repair.assignedToRoles.length - 1 > idx ? "," : " " }}<br />
          </span>
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.started_at">
        <dt>{{ $t("REPAIRS.STARTED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.started_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.startedBy
        "
      >
        <dt>{{ $t("REPAIRS.STARTED_BY") }}</dt>
        <dd>
          <object-link :object="repair.startedBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.ended_at">
        <dt>{{ $t("REPAIRS.ENDED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.ended_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) && !!repair.endedBy
        "
      >
        <dt>{{ $t("REPAIRS.ENDED_BY") }}</dt>
        <dd>
          <object-link :object="repair.endedBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.started_times_at">
        <dt>{{ $t("REPAIRS.STARTED_TIMES_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.started_times_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.startedTimesBy
        "
      >
        <dt>{{ $t("REPAIRS.STARTED_TIMES_BY") }}</dt>
        <dd>
          <object-link :object="repair.startedTimesBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.ended_times_at">
        <dt>{{ $t("REPAIRS.ENDED_TIMES_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.ended_times_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.endedTimesBy
        "
      >
        <dt>{{ $t("REPAIRS.ENDED_TIMES_BY") }}</dt>
        <dd>
          <object-link :object="repair.endedTimesBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.started_approve_at">
        <dt>{{ $t("REPAIRS.STARTED_APPROVE_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.started_approve_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.startedApproveBy
        "
      >
        <dt>{{ $t("REPAIRS.STARTED_APPROVE_BY") }}</dt>
        <dd>
          <object-link :object="repair.startedApproveBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.ended_approve_at">
        <dt>{{ $t("REPAIRS.ENDED_APPROVE_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.ended_approve_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.endedApproveBy
        "
      >
        <dt>{{ $t("REPAIRS.ENDED_APPROVE_BY") }}</dt>
        <dd>
          <object-link :object="repair.endedApproveBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.reviewed_at">
        <dt>{{ $t("REPAIRS.REVIEWED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(repair.reviewed_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.reviewedBy
        "
      >
        <dt>{{ $t("REPAIRS.REVIEWED_BY") }}</dt>
        <dd>
          <object-link :object="repair.reviewedBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.canceled_at">
        <dt>
          {{ $t("REPAIRS.CANCELED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(repair.canceled_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.canceledBy
        "
      >
        <dt>
          {{ $t("REPAIRS.CANCELED_BY") }}
        </dt>
        <dd>
          <object-link :object="repair.canceledBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!repair.completed_at">
        <dt>
          {{ $t("REPAIRS.COMPLETED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(repair.completed_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!repair.completedBy
        "
      >
        <dt>
          {{ $t("REPAIRS.COMPLETED_BY") }}
        </dt>
        <dd>
          <object-link :object="repair.completedBy" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
          !!repair.organization
        "
      >
        <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd>
          <organization :organization="repair.organization" />
        </dd>
      </dl>
    </div>
    <inspection-confirmation-modal
      :confirm-action="cancelRepair"
      :confirm-button-text="$t('COMMON.YES_CANCEL')"
      :loading="loading"
      :message="$t('REPAIRS.CANCEL_THIS_REPAIR')"
      :open.sync="showRepairCancelModel"
      modal-classes="modal-secondary cancel"
    />
  </div>
</template>

<script>
import { REPAIR_STATUS_CANCELED } from "@/constants/repairs";

import Organization from "@/components/Organization.vue";
import RepairRefStandardized from "@/components/RepairRefStandardized.vue";
import RepairStatusBadge from "./RepairStatusBadge.vue";
import Users from "@/components/Users.vue";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";

export default {
  name: "repair-view-global",

  components: {
    Users,
    Organization,
    RepairStatusBadge,
    InspectionConfirmationModal,
    RepairRefStandardized,
  },

  mixins: [repairPermissionsMixin],

  props: {
    repair: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    viewHeader: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      REPAIR_STATUS_CANCELED,
      QUERY_ACTIONS_VIEW,
      showRepairCancelModel: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    repairUpdated() {
      this.$emit("repairUpdated", true);
    },
    cancelRepair() {
      this.$emit("onCancelRepair", true);
    },
    editRepair() {
      this.$emit("onEditRepair", true);
    },
  },

  mounted() {},

  watch: {
    repair(repair) {},
  },
};
</script>
