<template>
  <div :class="className">
    <inspection-ref-standardized
      v-if="viewHeader"
      :show-buttons="showButtons"
      :canCancel="canCancelInspection"
      :canEdit="canEditInspection"
      :canRedo="canRedoInspection"
      :canShare="canShareInspection"
      :inspection="inspection"
      @cancel="showInspectionCancelModel = true"
      @edit="editInspection"
      @redo="showRedoInspectionConfirmationModel = true"
      @share="shareInspection"
      @vehicleChanged="$emit('onViewInspection')"
    />

    <slot name="header">
      <div class="global-header align-items-baseline justify-content-between">
        <h3>
          {{ inspection.code }}
        </h3>
        <div class="global-header-text">
          <div class="buttons">
            <slot name="buttons">
              <button
                v-if="canCancelInspection"
                @click="showInspectionCancelModel = true"
                class="delete"
              >
                <span> {{ $t("COMMON.CANCEL") }} </span>
                <i class="far fa-trash-alt"></i>
              </button>
              <button
                v-if="canEditInspection"
                @click="editInspection"
                class="edit"
              >
                <span> {{ $t("INSPECTIONS.UPDATE") }} </span>
                <i class="far fa-edit"></i>
              </button>
              <button
                v-if="canShareInspection"
                @click="shareInspection"
                class="edit"
              >
                <span> {{ $t("COMMON.SHARE") }} </span>
                <i class="fal fa-share"></i>
              </button>
              <button
                v-if="canRedoInspection"
                @click="showRedoInspectionConfirmationModel = true"
                class="delete"
              >
                <span> {{ $t("INSPECTIONS.REDO_INSPECTION") }} </span>
                <i class="far fa-edit"></i>
              </button>
            </slot>
          </div>
        </div>
      </div>
    </slot>

    <div class="global-body">
      <dl class="width_1-2" v-if="showCode">
        <dt>{{ $t("COMMON.CODE") }}</dt>
        <dd>
          <!-- <object-link :object="inspection" /> -->
          {{ $objectDenomination(inspection) }}
        </dd>
      </dl>

      <dl class="width_1-2" v-if="inspection.work_order">
        <dt>{{ $t("COMMON.WORK_ORDER") }}</dt>
        <dd>
          {{ inspection.work_order }}
        </dd>
      </dl>

      <dl class="width_1-2 status">
        <dt>{{ $t("COMMON.STATUS") }}</dt>
        <dd>
          <inspection-status-badge :inspection="inspection" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="inspection.status == INSPECTION_STATUS_COMPLETED"
      >
        <dt>{{ $t("INSPECTIONS.SCORE") }}</dt>
        <dd>{{ inspection.score }}%</dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          $idExist(inspection.customer?.id) &&
          !!inspection.customer?.customer_type
        "
      >
        <dt>{{ $t("COMMON.CUSTOMER") }}</dt>
        <dd>
          <users :users="[inspection.customer]" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_REQUESTS) &&
          !!inspection.request
        "
      >
        <dt>{{ $t("COMMON.REQUEST") }}</dt>
        <dd>
          <object-link :object="inspection.request" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          $idExist(inspection.approver?.id) &&
          !!inspection.approver?.email
        "
      >
        <dt>{{ $t("COMMON.APPROVER") }}</dt>
        <dd>
          <object-link :object="inspection.approver" />
        </dd>
      </dl>

      <!-- <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
          !!inspection.vehicle
        "
      >
        <dt>{{ $t("COMMON.VEHICLE") }}</dt>
        <dd>
          <object-link :object="inspection.vehicle" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
          !!inspection.vehicle
        "
      >
        <dt>{{ $t("VEHICLES.ODOMETER") }}</dt>
        <dd>
          {{ inspection.odometer ?? inspection.vehicle?.odometer }}
          {{ inspection.vehicle?.odometer_unit }}
        </dd>
      </dl> -->

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_INSPECTION_FORMS) &&
          !!inspection.inspectionForm
        "
      >
        <dt>{{ $t("COMMON.INSPECTION_FORM") }}</dt>
        <dd>
          <object-link :object="inspection.inspectionForm" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!inspection.created_at">
        <dt>{{ $t("INSPECTIONS.CREATED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.created_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.creator
        "
      >
        <dt>{{ $t("INSPECTIONS.CREATOR") }}</dt>
        <dd>
          <object-link :object="inspection.creator" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!inspection.approved_at">
        <dt>{{ $t("INSPECTIONS.APPROVED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.approved_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.approvedBy
        "
      >
        <dt>{{ $t("INSPECTIONS.APPROVED_BY") }}</dt>
        <dd>
          <object-link :object="inspection.approvedBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!inspection.assigned_at">
        <dt>{{ $t("INSPECTIONS.ASSIGNED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.assigned_at, "LLLL") }}
        </dd>
      </dl>
      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.assignedBy
        "
      >
        <dt>{{ $t("INSPECTIONS.ASSIGNED_BY") }}</dt>
        <dd>
          <object-link :object="inspection.assignedBy" />
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.assignedToUsers?.length
        "
      >
        <dt>{{ $t("INSPECTIONS.ASSIGNED_TO") }}</dt>
        <dd>
          <span v-for="(user, idx) in inspection.assignedToUsers" :key="idx">
            <object-link :object="user" />
            {{ inspection.assignedToUsers.length - 1 > idx ? "," : " " }}<br />
          </span>
        </dd>
      </dl>
      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ROLES) &&
          !!inspection.assignedToRoles?.length
        "
      >
        <dt>{{ $t("INSPECTIONS.ASSIGNED_TO_GROUP") }}</dt>
        <dd>
          <span v-for="(role, idx) in inspection.assignedToRoles" :key="idx">
            <object-link :object="role" />
            {{ inspection.assignedToRoles.length - 1 > idx ? "," : " " }}<br />
          </span>
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!inspection.started_at">
        <dt>
          {{ $t("INSPECTIONS.STARTED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.started_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.startedBy
        "
      >
        <dt>
          {{ $t("INSPECTIONS.STARTED_BY") }}
        </dt>
        <dd>
          <object-link :object="inspection.startedBy" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="!!inspection.completed_at">
        <dt>
          {{ $t("INSPECTIONS.COMPLETED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.completed_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.completedBy
        "
      >
        <dt>
          {{ $t("INSPECTIONS.COMPLETED_BY") }}
        </dt>
        <dd>
          <object-link :object="inspection.completedBy" />
        </dd>
      </dl>
      <dl class="width_1-2" v-if="inspection.canceled_at">
        <dt>
          {{ $t("INSPECTIONS.CANCELED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.canceled_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.canceledBy
        "
      >
        <dt>
          {{ $t("INSPECTIONS.CANCELED_BY") }}
        </dt>
        <dd>
          <object-link :object="inspection.canceledBy" />
        </dd>
      </dl>

      <dl class="width_1-2">
        <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd>
          <locations :locations="inspection.allowedLocations" />
        </dd>
      </dl>
      <dl
        class="width_1-2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
          !!inspection.organization
        "
      >
        <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd>
          <organization :organization="inspection.organization" />
        </dd>
      </dl>

      <dl class="width_1-2" v-if="false">
        <dt>
          {{ $t("COMMON.TAGS") }}
        </dt>
        <dd>
          <tags :tags="inspection.tags" @tagsUpdated="inspectionUpdated" />
        </dd>
      </dl>

      <dl class="width_1-2">
        <dt>{{ $t("INSPECTIONS.EXCERPT") }}</dt>
        <dd v-html="inspection.excerpt"></dd>
      </dl>
    </div>
    <inspection-confirmation-modal
      :confirm-action="cancelInspection"
      :confirm-button-text="$t('COMMON.YES_CANCEL')"
      :loading="loading"
      :message="$t('INSPECTIONS.CANCEL_THIS_INSPECTION')"
      :open.sync="showInspectionCancelModel"
      modal-classes="modal-secondary cancel"
    />
    <inspection-confirmation-modal
      :confirm-action="redoInspection"
      :confirm-button-text="$t('COMMON.CONTINUE')"
      :cancelButtonText="$t('COMMON.CANCEL')"
      :loading="loading"
      :message="$t('INSPECTIONS.REDO_WARNING')"
      :open.sync="showRedoInspectionConfirmationModel"
      modal-classes="modal-secondary cancel"
    />
  </div>
</template>

<script>
import {
  INSPECTION_STATUS_ASSIGNED,
  INSPECTION_STATUS_DRAFT,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_COMPLETED,
} from "@/constants/inspections";
import Tags from "@/components/Tags.vue";
import Organization from "@/components/Organization.vue";
import InspectionStatusBadge from "./InspectionStatusBadge.vue";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import Users from "@/components/Users.vue";
import InspectionRefStandardized from "@/components/InspectionRefStandardized.vue";
import RequestErrorMixin from "@/mixins/request-error-mixin";
import swal from "sweetalert2";

export default {
  name: "inspection-view-global",

  mixins: [RequestErrorMixin],

  components: {
    Tags,
    Organization,
    InspectionStatusBadge,
    InspectionConfirmationModal,
    Users,
    InspectionRefStandardized,
  },

  props: {
    inspection: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    viewHeader: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "elite-tabs-wrapper-content",
    },
    showCode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      QUERY_ACTIONS_VIEW,
      showInspectionCancelModel: false,
      showRedoInspectionConfirmationModel: false,
      INSPECTION_STATUS_COMPLETED,
    };
  },

  computed: {
    canCancelInspection() {
      return (
        this.showButtons &&
        !!this.inspection &&
        this.$currentUserCan(this.$permissions.PERM_CANCEL_INSPECTIONS) &&
        [
          INSPECTION_STATUS_DRAFT,
          INSPECTION_STATUS_PENDING,
          INSPECTION_STATUS_ASSIGNED,
        ].includes(this.inspection.status)
      );
    },
    canEditInspection() {
      return (
        this.showButtons &&
        !!this.inspection &&
        ![INSPECTION_STATUS_CANCELED, INSPECTION_STATUS_COMPLETED].includes(
          this.inspection.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },

    canShareInspection() {
      return (
        this.showButtons &&
        !!this.inspection &&
        !!this.inspection.share_key &&
        this.inspection.status == INSPECTION_STATUS_COMPLETED
      );
    },

    canRedoInspection() {
      return (
        this.inspection.status == INSPECTION_STATUS_COMPLETED &&
        this.$currentUserCan(this.$permissions.PERM_CREATE_INSPECTIONS)
      );
    },
  },

  created() {},

  methods: {
    inspectionUpdated() {
      this.$emit("inspectionUpdated", true);
    },
    cancelInspection() {
      this.$emit("onCancelInspection");
    },
    editInspection() {
      this.$emit("onEditInspection");
    },
    shareInspection() {
      this.$emit("onShareInspection", true);
    },
    async redoInspection() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("inspections/redo", {
          inspectionId: this.inspection.id,
        });
        this.$emit("inspectionUpdated");
        this.showRedoInspectionConfirmationModel = false;

        const newInspection = await this.$store.getters[
          "inspections/inspection"
        ];

        this.$emit("onViewInspection", newInspection);
      } catch (error) {
        swal.close();
        console.log(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {},

  watch: {
    inspection(inspection) {},
  },
};
</script>
