<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!inspection">
      <span class="loader"></span>
    </span>
    <div class="pl-3 pl-md-4" v-if="inspection">
      <vehicle-ref-standardized
        :vehicle="inspection.vehicle"
        @vehicleChanged="onViewInspection"
        gallery-selector
      >
        <template #second-title>
          <h4>{{ inspection.code }}</h4>
        </template>
      </vehicle-ref-standardized>
    </div>
    <div class="elite-tabs-wrapper" v-if="inspection">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper tabs-wrapper-nav"
        tabNavClasses="nav elite-tabs"
        :value="getActiveTab()"
        @onTabChanged="tabChanged"
      >
        <tab-pane title="global" id="1" :active="false">
          <span slot="title">
            <i class="fa-regular fa-list"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <inspection-view-global
            :inspection="inspection"
            :loading="loading"
            :view-header="false"
            @inspectionUpdated="inspectionUpdated"
            @onCancelInspection="cancelInspection"
            @onEditInspection="editInspection"
            @onShareInspection="shareInspection"
            @onViewInspection="onViewInspection"
          />
        </tab-pane>

        <tab-pane title="images" id="2" :active="false">
          <span slot="title">
            <i class="fa-regular fa-image"></i>
            {{ $t("COMMON.PICTURES") }}
          </span>
          <inspection-view-images :inspection="inspection" />
        </tab-pane>

        <tab-pane
          title="inspection"
          id="3"
          :active="true"
          v-if="canViewInspectionTab"
        >
          <span class="title" slot="title">
            <i class="fa-regular fa-clipboard-check"></i>
            {{ $t("COMMON.INSPECTION") }}
          </span>
          <div class="tab-pane-inner">
            <inspection-view-inspection
              :inspection="inspection"
              :loading="inspectionLoading || loading"
              @inspectionUpdated="inspectionUpdated"
              @onCloseInspection="onCloseInspection"
              @onEditInspection="editInspection"
              @onStartInspection="startInspection"
              @onTakeOverInspection="takeOverInspection"
              @onViewInspection="onViewInspection"
            />
          </div>
        </tab-pane>

        <tab-pane
          title="estimation"
          id="4"
          :active="true"
          v-if="
            !!inspection.estimation &&
            !inspection.estimation.by_pass &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ESTIMATIONS)
          "
        >
          <span class="title" slot="title">
            <i class="far fa-usd-circle"></i>
            {{ $t("COMMON.ESTIMATION") }}
          </span>
          <div class="tab-pane-inner">
            <inspection-view-estimate
              :loading="inspectionLoading || loading"
              :inspection="inspection"
              @onViewInspection="onViewInspection"
            />
          </div>
        </tab-pane>

        <tab-pane
          title="repair"
          id="5"
          :active="true"
          v-if="
            !!inspection.repair &&
            $currentUserCan($permissions.PERM_VIEW_ANY_REPAIRS)
          "
        >
          <span class="title" slot="title">
            <i class="fa-regular fa-wrench"></i>
            {{ $t("COMMON.REPAIR") }}
          </span>
          <div class="tab-pane-inner">
            <inspection-view-repair
              :loading="inspectionLoading || loading"
              :inspection="inspection"
              @onViewInspection="onViewInspection"
            />
          </div>
        </tab-pane>

        <tab-pane
          v-if="canViewVehicleTab() && $idExist(inspection.vehicle?.id)"
          :active="false"
          id="6"
          title="vehicle"
        >
          <span class="title" slot="title">
            <i class="fa-regular fa-car"></i>
            {{ $t("COMMON.VEHICLE") }}
          </span>
          <div class="tab-pane-inner">
            <inspection-view-vehicle
              :inspection="inspection"
              @inspectionUpdated="inspectionUpdated"
              @updateInspection="handleSubmit"
              :loading="inspectionLoading"
              :form-errors="formErrors"
              @onViewInspection="onViewInspection"
            />
          </div>
        </tab-pane>

        <tab-pane
          title="client"
          id="7"
          :active="false"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)"
        >
          <span class="title" slot="title">
            <i class="fa-regular fa-user"></i>
            {{ $t("COMMON.CUSTOMER") }}
          </span>
          <div class="tab-pane-inner">
            <inspection-view-customer
              :inspection="inspection"
              @updateInspection="handleSubmit"
              :loading="inspectionLoading"
              :form-errors="formErrors"
              @onViewInspection="onViewInspection"
            />
          </div>
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="far fa-file-alt"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="inspection" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-history"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <inspection-view-logs :inspection="inspection" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import {
  INSPECTION_STATUS_DRAFT,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_COMPLETED,
} from "@/constants/inspections";
import { Tabs, TabPane } from "@/components";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import InspectionViewLogs from "../partials/InspectionViewLogs.vue";
import InspectionViewGlobal from "../partials/InspectionViewGlobal.vue";
import InspectionViewInspection from "../partials/InspectionViewInspection.vue";
import InspectionViewVehicle from "../partials/InspectionViewVehicle.vue";
import InspectionViewCustomer from "../partials/InspectionViewCustomer.vue";
import InspectionViewImages from "../partials/InspectionViewImages.vue";
import defaultCustomer from "../../../CrmModule/CustomerManagement/defaultCustomer";
import InspectionViewEstimate from "../partials/InspectionViewEstimate.vue";
import InspectionViewRepair from "../partials/InspectionViewRepair.vue";
import hasTabs from "@/mixins/has-tabs";
import { mapGetters } from "vuex";
import VehicleRefStandardized from "@/components/VehicleRefStandardized.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    InspectionViewGlobal,
    InspectionViewLogs,
    ListFileComponent,
    InspectionViewInspection,
    InspectionViewVehicle,
    InspectionViewImages,
    InspectionViewCustomer,
    InspectionViewEstimate,
    InspectionViewRepair,
    VehicleRefStandardized,
  },

  mixins: [requestErrorMixin, hasTabs],

  props: {
    inspectionId: {
      type: [String, Number],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formErrors: null,
      inspectionLoading: false,
      INSPECTION_STATUS_DRAFT: INSPECTION_STATUS_DRAFT,
      customer: cloneDeep(defaultCustomer),
    };
  },

  computed: {
    ...mapGetters({
      inspection: "inspections/currentInspection",
    }),

    canViewInspectionViewModal() {
      return ![INSPECTION_STATUS_DRAFT, INSPECTION_STATUS_PENDING].includes(
        this.inspection?.status
      );
    },

    canViewInspectionTab: function () {
      return (
        !!this.inspection &&
        (this.$currentUserCan(this.$permissions.PERM_START_INSPECTIONS) ||
          this.inspection.status == INSPECTION_STATUS_COMPLETED ||
          this.$currentUserCan(
            this.$permissions.PERM_VIEW_IN_PROGRESS_INSPECTIONS
          ))
      );
    },
  },

  watch: {
    inspection() {},
  },

  async created() {
    await this.get();
  },

  methods: {
    async get(filterSections = []) {
      this.inspectionLoading = true;
      try {
        await this.$store.dispatch("inspections/get", {
          id: this.inspectionId,
          params: { filter_sections: filterSections },
        });
        const inspection = this.$store.getters["inspections/inspection"];
        await this.$store.dispatch(
          "inspections/setCurrentInspection",
          inspection
        );
        if (this.canViewInspectionViewModal) {
          this.$emit("inspectionLoaded", inspection);
        } else {
          this.$emit("onEditInspection", inspection);
        }
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.inspectionLoading = false;
      }
    },

    async patchInspection(inspectionData) {
      this.inspectionLoading = true;
      try {
        await this.$store.dispatch("inspections/update", {
          inspection: inspectionData,
          params: {},
        });
        const inspection = this.$store.getters["inspections/inspection"];
        await this.$store.dispatch(
          "inspections/setCurrentInspection",
          inspection
        );
        if (this.canViewInspectionViewModal) {
          this.$emit("inspectionLoaded", this.inspection);
        } else {
          this.$emit("onEditInspection", this.inspection);
        }
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.inspectionLoading = false;
      }
    },

    async inspectionUpdated(changedSection = null, oldSection = null) {
      if (changedSection) {
        let touchedSections = cloneDeep(this.inspection.touched_sections);
        touchedSections.push(changedSection.id);
        touchedSections = [...new Set(touchedSections)];
        await this.patchInspection({
          type: "inspections",
          id: this.inspection.id,
          touched_sections: touchedSections,
          filter_sections: [changedSection?.id, oldSection?.id].filter(
            (item) => !!item
          ),
        });
      } else {
        await this.get(
          [changedSection?.id, oldSection?.id].filter((item) => !!item)
        );
      }

      this.$emit("inspectionUpdated", true);
    },

    getActiveTab() {
      if (this.inspection.status == INSPECTION_STATUS_DRAFT) {
        if (!!this.inspection.vehicle) {
          return "global";
        } else {
          return "vehicle";
        }
      }
      if (this.inspection.status == INSPECTION_STATUS_PENDING) {
        return "global";
      }
      if (this.inspection.status == INSPECTION_STATUS_CANCELED) {
        return "global";
      }
      if (!!this.inspection.vehicle) {
        return "inspection";
      }
      if (!this.inspection.vehicle) {
        return "vehicle";
      }
      return this.currentTab;
    },

    canViewVehicleTab() {
      return !!this.inspection;
    },

    async cancelInspection() {
      await this.get();
      this.$emit("onCancelInspection", this.inspection);
    },
    editInspection() {
      this.$emit("onEditInspection", this.inspection);
    },
    shareInspection() {
      console.log("ShareInspection");
      this.$emit("onShareInspection", this.inspection);
    },
    startInspection() {
      this.$emit("onStartInspection", this.inspection);
    },
    takeOverInspection() {
      this.$emit("onTakeOverInspection", this.inspection);
    },

    async handleSubmit(inspectionData) {
      this.inspectionLoading = true;
      swal.showLoading();
      try {
        await this.$store.dispatch("inspections/update", {
          inspection: inspectionData,
          params: {},
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_UPDATED"),
        });
        await this.inspectionUpdated();
      } catch (error) {
        this.formErrors = error.response?.data?.errors;
      } finally {
        swal.close();
        this.inspectionLoading = false;
      }
    },

    onViewInspection(inspection = null) {
      if (!inspection) {
        inspection = this.inspection;
      }
      this.$emit("onViewInspection", inspection, true);
    },

    onCloseInspection(inspection) {
      this.$emit("onCloseInspection");
    },
  },
};
</script>
