<template>
  <repair-view-global
    class-name=""
    :repair="inspection.repair"
    :view-header="false"
    :show-code="false"
  >
    <template #buttons>
      <button class="edit" @click="showRepair" v-if="canShowRepair">
        <span> {{ $t("REPAIRS.SHOW_REPAIR") }} </span>
        <i class="fa-light fa-eye"></i>
      </button>
    </template>
  </repair-view-global>
</template>

<script>
import InspectionRefStandardized from "@/components/InspectionRefStandardized.vue";
import RepairViewGlobal from "../../RepairManagement/partials/RepairViewGlobal.vue";

export default {
  name: "inspection-view-repair",

  components: { RepairViewGlobal, InspectionRefStandardized },

  props: ["inspection"],

  data() {
    return {};
  },

  computed: {
    canShowRepair: function () {
      return (
        !!this.inspection.repair &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_MODULE_REPAIRS) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_REPAIRS)
      );
    },
    title: function () {
      return `${this.inspection.code} - ${this.$t("COMMON.REPAIR")}`;
    },
  },

  created() {},

  methods: {
    showRepair() {
      this.$router.push(this.$objectViewRoute(this.inspection.repair));
    },
  },

  mounted() {},

  watch: {},
};
</script>
